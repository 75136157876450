/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.edge h2 > em,
.edge h2 > .open {
  float: right;
  margin-top: -1.7em;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 1.38888889%;
  margin-left: 1.38888889%;
  width: 97.22222222%;
}
.area h2,
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim {
  width: 47.22222222%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 100%;
}
.area > .slim .tiny {
  width: 100%;
}
.wide.pure,
.wide.seam {
  width: 58.33333333%;
}
.slim.pure,
.slim.seam {
  width: 36.11111111%;
}
.flat.wide {
  width: 100% !important;
  margin: 0 !important;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.cb-page-layout2 .area {
  display: flex;
  flex-wrap: wrap;
}
.cb-page-layout2 .unit {
  display: flex;
  flex-direction: column;
}
#view .cb-page-layout2 .show:only-child {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
}
#view .cb-page-layout2 .show:only-child img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: 'object-fit: cover; object-position: 50% 50%;';
}
#view .cb-page-layout2 .unit > .body,
#view .cb-page-layout2 .show:only-child {
  flex: 1;
  align-self: stretch;
  height: 100%;
}
.flat.wide h2,
.flat.wide .part.part,
.flat.wide .foot {
  margin: 1.38888889%;
}
.flat.wide .tall {
  width: 58.33333333%;
}
.flat.wide .tiny {
  width: 36.11111111%;
}
.flat.wide .show.tall:first-child ~ .show.tiny {
  float: right;
}
.flat.wide .show.tall:first-child ~ .show.tiny {
  clear: right;
}
.flat.wide .show.tiny:first-child + .show.tall {
  float: right;
}
.flat.wide .show.tiny:first-child ~ .show.tiny {
  clear: left;
}
.flat.wide > .body {
  position: relative;
}
.flat.wide .show.tiny:first-child:nth-last-child(2) ~ .show,
.flat.wide .show:first-child:nth-last-child(2) ~ .show.tiny {
  position: absolute;
  top: 0;
  bottom: 0;
}
.flat.wide .show.tiny:first-child:nth-last-child(2) ~ .show img,
.flat.wide .show:first-child:nth-last-child(2) ~ .show.tiny img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: 'object-fit: cover; object-position: 50% 50%;';
}
.flat.wide .show:first-child:nth-last-child(2) {
  left: 0;
}
.flat.wide .show:first-child:nth-last-child(2) ~ .show {
  right: 0;
}
.flat.wide .show:first-child:nth-last-child(4),
.flat.wide .show:first-child:nth-last-child(4) ~ .show,
.flat.wide .show:first-child:nth-last-child(3),
.flat.wide .show:first-child:nth-last-child(3) ~ .show {
  height: 0 !important;
}
.flat.wide .show:first-child:nth-last-child(4) img,
.flat.wide .show:first-child:nth-last-child(4) ~ .show img,
.flat.wide .show:first-child:nth-last-child(3) img,
.flat.wide .show:first-child:nth-last-child(3) ~ .show img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: 'object-fit: cover; object-position: 50% 50%;';
}
#view .flat.wide .show {
  overflow: hidden;
}
.flat.wide .show.tall:first-child:nth-last-child(4),
.flat.wide .show:first-child:nth-last-child(4) ~ .show.tall,
.flat.wide .show.tall:first-child:nth-last-child(3),
.flat.wide .show:first-child:nth-last-child(3) ~ .show.tall {
  padding-bottom: 54.90625%;
}
.flat.wide .show.tiny:first-child:nth-last-child(4),
.flat.wide .show:first-child:nth-last-child(4) ~ .show.tiny {
  padding-bottom: 16.45023148%;
}
.flat.wide .show.tiny:first-child:nth-last-child(3),
.flat.wide .show:first-child:nth-last-child(3) ~ .show.tiny {
  padding-bottom: 26.06423611%;
}
.area.area {
  width: 102.85714286%;
  margin-left: -1.42857143%;
}
.unit {
  margin-top: 1.38888889%;
  margin-bottom: 1.38888889%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */